@import './common_css/mandatoryFieldLabel.css';
@import './common_css/normalFieldLabel.css';
@import './common_css/loadingOverlay.css';
@import './common_css/cropper.css';
@import './common_css/uploadImage.css';
@import './common_css/HTMLTextEditor.css';
@import './common_css/reactTable.css';

@import './colors.css';

.plugin_container {
    padding-left: 15px;
    padding-top: 15px;
    padding-right: 15px;
    height: 95vh;
}

.ow_activity_halfbold{
    font-weight: 500;
}

.half_margin_top_row {
    margin-top: 0.5rem;
}

.margin_top_row {
    margin-top: 1rem;
}

.add_button {
    color: var(--primary--color);
    cursor: pointer;
    font-size: 1.5rem;
    margin-top: 2rem;
    float: "right";
}

.add_button_disabled {
    color: var(--busy--color);
    cursor: not-allowed;
    font-size: 1.5rem;
    margin-top: 2rem;
    float: "right";
}

.add_button_dishes {
    color: var(--primary--color);
    cursor: pointer;
    font-size: 1.5rem;
    float: "right";
}

.add_button_dishes_disabled {
    color: var(--busy--color);
    cursor: not-allowed;
    font-size: 1.5rem;
    float: "right";
}

.add_vintage_button {
    color: var(--primary--color);
    cursor: pointer;
    font-size: 1.2rem;
    float: "right";
}

.edit_header_icon_save {
    cursor: pointer;
    margin-right: 15px;
    font-size: 1.5rem;
    color: var(--primary--color)
}

.edit_header_icon_close {
    cursor: pointer;
    font-size: 1.5rem;
    color: grey
}

.custom_error_messages {
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.category_image {
    min-height: 3rem;
    min-width: 3rem;
    max-height: 5rem;
    max-width: 5rem;
}

.category_image_mini {
    min-height: 1rem;
    min-width: 1rem;
    max-height: 3rem;
    max-width: 3rem;
}

.dish_image {
    min-height: 3rem;
    min-width: 3rem;
    max-height: 5rem;
    max-width: 5rem;
}

.dish_type_icon {
    color: var(--primary--color);
    font-size: 1.5rem;
}

.align_text_right {
    text-align: right;
}

.custom_checkbox {
    padding-top: 0.5rem;
}

.custom_checkbox input[type=checkbox] {
    transform: scale(1.5);
}

.card_open_icon {
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--busy--color);
    float: right;
}

.card_open_icon_disabled {
    cursor: not-allowed;
    font-size: 1.5rem;
    color: var(--disabled--color);
    float: right;
}

.disabled_drink_info {
    color: var(--disabled--color);
}

.dish_icon {
    max-width: 1.5rem;
}

.first_vintage_row {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--disabled--color);
}

.vintage_row {
    padding-top: 1rem;
    border-bottom: 1px solid var(--disabled--color);
    padding-bottom: 1rem;
}

.last_vintage_row {
    padding-top: 1rem;
}

.vintage_quantity_col {
    padding-right: 0px;
}

.vintage_quantity_unit_col {
    padding-left: 0px;
}

.delete_vintage_icon {
    cursor: pointer;
    float: right;
    font-size: 1.5rem;
    color: var(--declined--color)
}

.delete_reccomended_item_col {
    padding-left: 0px;
}

.delete_reccomended_item_icon {
    cursor: pointer;
    margin-top: 0.5rem;
    font-size: 1.3rem;
    color: var(--declined--color)
}

.reccomended_item_row {
    margin-top: 1rem;
}

.visibility_icon_false {
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--disabled--color)
}

.visibility_icon_true {
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--primary--color)
}

.visibility_icon_middle {
    cursor: pointer;
    margin-right: 15px;
    margin-left: 15px;
    font-size: 1.5rem;
}

.dropdown_icon {
    font-size: 1.2rem;
    color: var(--busy--color)
}

input[type="color"] {
    width: 3rem !important;
}

.device_detail_section {
    border-bottom: 1px solid #007bff;
}